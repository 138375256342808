@media only screen and (max-width: 600px) {
    
    :root {
        --light-pink: #F4ADDF;
        --dusty-pink: #A17386;
        --mustard: #E5D737;
        --sky-blue: #CDDFEB;
        --purple: #7F4A8B;
        --app-height: 100%;
        --section-height: calc(var(--app-height) - 40px);
        --app-width: calc(100vW - 20px);
        --app-padding: 10vH 10px 20px 10px;
      } 

    /* === NAVIGATION === */

    .nav {
        height: 8vH;
        padding: 0px 10px;
        width: var(--app-width);
    }
      
    .nav * {
        color: var(--light-pink);
        margin: 0px;
    }
      
    .menu-icon-title-container > h1 {
        font-size: 40px;
        line-height: 38px;
    }
      
    .menu-icon {
        height: 30px;
    }
      
    .menu-container {
        display: flex;
        flex-direction: column;
        height: calc(var(--app-height) - 8vH);
    }
      
    .menu-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100vW;
        transition-duration: 200ms;
        overflow: hidden;
    }
      
    .nav-wrapper {
        position: fixed;
        top: 0px;
        z-index: 10;
    }

    /* === ABOUT === */

    .intro-image {
        height: calc(var(--app-height) - 20vH);
        position: relative;
        width: var(--app-width);
    }
        
    .overlay {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        mix-blend-mode: overlay;
        background-position: center;
        background-size: cover;
    }
        
    .intro-image-bar {
        background-color: var(--mustard);
        height: 50px;
        width: 100vW;
    }
        
    .biography {
        background-color: var(--sky-blue);
    }
        
    .biography > div {
        width: 100%;
    }
        
    .about-page-section {
        flex-direction: column-reverse;
        justify-content: space-around;
        padding: 0px;
        width: 100%;
    }
        
    .about-page-section-final {
        position: absolute;
        mix-blend-mode: screen;
        width: 100%;
        height: var(--section-height);
        left: 0px;
        z-index: 0;
    }
        
    .about-page-section-reverse {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        height: var(--section-height);
        padding: 0px;
        width: 100%;
    }
        
    .section-title-card {
        background-color: white;
        align-items: start;
        padding: 10px;
        width: var(--app-width);
    }
        
    .section-title-card > h1 {
        padding: 0px;
        margin: 0px;
        z-index: 1;
    }
        
    .section-bg-image {
        position: absolute;
        z-index: 0;
        width: var(--app-width);
    }
        
    .section-image-card {
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
        
    .section-main-image {
        width: 95%;
    }
        
    #tape-1 {
        position: absolute;
        top: -10%;
        right: 0px;
        width: 30%;
    }
        
    #tape-2 {
        position: absolute;
        top: -20%;
        left: 0px;
        width: 30%;
    }
        
    .relative {
        position: relative;
        display: flex;
        justify-content: center;
    }

    /* === WRITING LIST === */

    .writing-list-page {
        flex-direction: column;
        justify-content: start;
        min-height: var(--app-height);
        padding: 8vH 0px 0px 0px;
    }

    .writing-list-page > h1 {
        padding-left: 10px;
    }
    
    .writing-list-container {
        border: none;
        padding: 0px;
        width: 100%;
    }
    
    .writing-card {
        height: 70vH;
        margin-bottom: 20px;
    }
    
    .writing-card-info {
        background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(55,55,207,0)100%);
        padding: 10px;
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }
    
    .writing-card-info > h1 {
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 0px;
    }
    
    /* === WRITING DETAIL === */

    .writing-page-container {
        display: flex;
        flex-direction: column;
        padding: 8vh 0px 0px 0px;
    }
      
    .menu-margin {
        background-color: var(--sky-blue);
        height: auto;
        padding-left: 10px;
        width: 100%;
    }
      
    .writing-container {
        background-color: white;
        width: 100%;
  }
      
    .dek {
        width: 100%;
    }
      
    .publication-info {
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
        height: 70vH;
    }
      
    .date-and-publisher {
        background-color: var(--light-pink);
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
      
    .date-and-publisher > h3 {
        margin: 0px;
    }
      
    .date-and-publisher > p {
        margin: 0px;
    }
      
    .texture-writing-detail {
        display: none;
    }

    /* === PERFORMANCE === */

    .single-image {
        width: 100vW;
    }
    
    .gallery-wrapper {
        flex-direction: column;
        row-gap: 20px;
    }
    
    .gallery-image {
        width: 100%;
        object-fit: cover;
    }
    
    .video {
        aspect-ratio: 16 / 9;
        width: 100%;
    }
    
    .performance-page {
        background-color: var(--mustard);
        overflow: hidden;
        position: relative;
    }
    
    .performance-menu {
        text-align: right;
    }
    
    .performance-menu > h1 > a {
        color: inherit;
        text-decoration: none;
    }
    
    .performance-container {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        position: relative;
        z-index: 2;
    }
    
    .performance-detail-texture {
        position: absolute;
        mix-blend-mode: darken;
        z-index: 0;
        left: -200px;
        top: -100px;
        pointer-events: none;
    }

    .project-information {
        flex-direction: column;
    }

    .project-information > div {
        width: 100%;
    }
  
  /* === Performance List === */
  
    .performance-list-page {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: var(--app-height);
        padding: 8vH 0px 0px 0px;
    }
    
    .performance-list-container {
        border-radius: 0px 0px 10px 10px;
        height: calc(var(--app-height) - 20vH);
        padding: 0px 0px;
        width: 100%;
    }
    
    .performance-list-container-border {
        border-right: 2px dashed var(--purple);
        border-left: 2px dashed var(--purple);
        border-bottom: 2px dashed var(--purple);
        border-radius: 0px 0px 10px 10px;
        height: calc(var(--app-height) - 20vH);
        position: absolute;
        overflow-y: visible; 
        width: 98%;
        z-index: 9;
        pointer-events: none;
        left: .5%;
    }
    
    .performance-card {
        background-position: center;
        background-size: cover;
        display: flex;
        height: 70vH;
        align-items: flex-end;
        margin-bottom: 20px;
    }
    
    .performance-card-info {
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(55,55,207,0)100%);
        color: white;
        padding: 10px;
    }
    
    .texture-top-left {
        display: none;
    }
    
    .texture-bottom-right {
        bottom: 0px;
        right: -200px;
        position: absolute;
        mix-blend-mode: darken;
    }

    /* === SYNDICATE === */

      
    .syndicate-intro {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
    }
      
    .syndicate-description {
        width: 100%;
    }
      
    .collaborator-grid {
        row-gap: 20px;
        justify-content: center;
    }
      
    .collaborator {
        width: 80%;
    }
      
    .collaborator > img {
        width: 100%;
    }

    .syndicate-projects {
        flex-direction: column;
    }
      
    .syndicate-project-list {
        position: relative;
        width: 100%;
    }
      
    .syndicate-project-list > a {
        text-decoration: none;
    }

  }