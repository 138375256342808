body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --light-pink: #F4ADDF;
  --dusty-pink: #A17386;
  --mustard: #E5D737;
  --sky-blue: #CDDFEB;
  --purple: #7F4A8B;
  --app-height: 100%;
  --section-height: calc(var(--app-height) - 40px);
  --app-width: calc(100vW - 120px);
  --app-padding: 10vH 60px 20px 60px;
} 

/* === Typography === */

h1 {
  font-family: "poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

h2 {
  font-family: "poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

h3 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-style: italic;
}

h4 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-style: italic;
}

p {
  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;
}

li {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-style: italic;
  list-style: none;
}

ul {
  padding-left: 0px;
}

a {
  color: inherit;
}
/* === Navigation === */

.nav {
  background-color: var(--dusty-pink);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vH;
  padding: 0px 60px;
  width: var(--app-width);
}

.nav * {
  color: var(--light-pink);
  margin: 0px;
}

.menu-icon-title-container {
  column-gap: 10px;
  display: flex;
  flex-direction: row;
}

.menu-icon-title-container > h1 {
  font-size: 48px;
  line-height: 44px;
}

.menu-icon-title-container > h1 > a {
  text-decoration: none;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
}

.menu-icon:hover {
  cursor: pointer;
}

.menu-bar {
  background-color: var(--light-pink);
  height: 6px;
  border-radius: 5px;
  transition-duration: 200ms;
}

#bar1 {
  width: 50px;
}

#bar2 {
  width: 30px;
}

#bar3 {
  width: 10px;
}

.menu-container {
  display: flex;
  height: calc(var(--app-height) - 10vH);
  column-gap: 20px;
  overflow: hidden;
}

.menu-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 33%;
  transition-duration: 200ms;
  overflow: hidden;
}

.menu-panel > a {
  color: black;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

#panel-one {
  background-color: var(--sky-blue);
}

#panel-two {
  background-color: var(--mustard);
  transition-delay: 200ms;
}

#panel-three {
  background-color: var(--purple);
  transition-delay: 400ms;
}

.nav-wrapper {
  position: fixed;
  top: 0px;
  z-index: 10;
}

.nav a {
  text-decoration: none;
}

h2 {
  position: relative;
}

h2:hover {
  cursor: pointer;
}

h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: 5px;
  left: 0;
  transform-origin: 0% 50%;
  background-color: var(--light-pink);
  transform: scaleX(0);
  transition: transform 0.3s ease;}

h2:hover::before {
  transform: scaleX(1);
}

h2:hover::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

/* === Footer === */

.footer {
  background-color: var(--dusty-pink);
  color: white;
  padding: 20px 60px;
  text-align: center;
}

.footer > h3 > a {
  color: white;
}

.footer > p > a {
  color: white;
  transition-duration: 200ms;
}

.footer > p > a:hover {
  color: var(--mustard);
  transition-duration: 200ms;
}

.footer > h3 {
  margin: 0px;
}

/* === Spacing === */

.page-container {
  padding: var(--app-padding);
}

/* === Background === */

.blue-background {
  background-color: var(--sky-blue);
}

.mustard-background {
  background-color: var(--mustard);
}
/* === About === */

.intro-image {
  height: calc(var(--app-height) - 20vH);
  position: relative;
  width: var(--app-width);
}

.overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  mix-blend-mode: overlay;
  background-position: center;
  background-size: cover;
}

.intro-image-bar {
  background-color: var(--mustard);
  height: 50px;
  width: 100vW;
}

.biography {
  background-color: var(--sky-blue);
}

.biography > div {
  width: 50%;
}

.about-page-section {
  background-color: var(--sky-blue);
  display: flex;
  height: var(--section-height);
  overflow: hidden;
  padding: var(--app-padding);
  width: var(--app-width);
}

.about-page-section-final {
  position: absolute;
  mix-blend-mode: screen;
  width: 100%;
  height: var(--section-height);
  left: 0px;
  z-index: 0;
}

.about-page-section-reverse {
  background-color: var(--sky-blue);
  display: flex;
  flex-direction: row-reverse;
  height: var(--section-height);
  padding: var(--app-padding);
  width: var(--app-width);
}

.section-title-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;
}

.section-title-card > h1 {
  z-index: 1;
}

.section-bg-image {
  mix-blend-mode: screen;
  width: 100%;
}

.section-image-card {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 67%;
}

.section-main-image {
  width: 90%;
}

#tape-1 {
  position: absolute;
  top: -10%;
  right: 0px;
  width: 30%;
}

#tape-2 {
  position: absolute;
  top: -20%;
  left: 0px;
  width: 30%;
}

.relative {
  position: relative;
}

/* === Writing Detail === */

.writing-page-container {
  display: flex;
  padding: var(--app-padding)
}

.menu-margin {
  background-color: var(--sky-blue);
  height: 100%;
  width: 33%;
}

.writing-container {
  background-color: white;
  width: 67%;
}

.dek {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-style: italic;
  margin: 0;
  width: 75%;
}

.title {
  margin-bottom: 0;
}

.publication-info {
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  height: 50vH;
}

.date-and-publisher {
  background-color: var(--light-pink);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.date-and-publisher > h3 {
  margin: 0px;
}

.date-and-publisher > p {
  display: flex;
  align-items: center;
  margin: 0px;
  vertical-align: baseline;
}

.title-and-dek {
  background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(55,55,207,0)100%);
  padding: 10px;
}

.publication-body {
  padding: 30px 20px 60px 20px;

}

.publication-body > h2 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 0px;
  margin-top: 40px;
}

.writing-color-bar {
  background-color: var(--mustard);
  height: 20px;
}

.menu-margin > a {
  color: inherit;
  text-decoration: none;
}

.texture-writing-detail {
  margin-top: 200px;
  mix-blend-mode: screen;
}

/* === Performance === */

.single-image {
  width: 100%;
}

.gallery-wrapper {
  display: flex;
  justify-content: space-between;
}

.gallery-image {
  width: 375px;
  object-fit: cover;
  height: 50vH
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.performance-page {
  background-color: var(--mustard);
  overflow: hidden;
  position: relative;
  padding: var(--app-padding);
}

.performance-menu {
  text-align: right;
}

.performance-menu > h1 > a {
  color: inherit;
  text-decoration: none;
}

.performance-container > h1 {
  margin: 0px;
}

.performance-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  position: relative;
  z-index: 2;
}

.performance-detail-texture {
  position: absolute;
  mix-blend-mode: darken;
  z-index: 0;
  left: -200px;
  pointer-events: none;
}

.project-information {
  display: flex;
  column-gap: 40px;
}

.project-information > div > p {
  margin-top: 0px;
}

.project-information > div > ul {
  margin-top: 0px;
}

.project-information > div {
  width: 50%;
}

/* === Performance List === */

.performance-list-page {
  background-color: var(--mustard);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  min-height: var(--app-height);
  padding: var(--app-padding);
  position: relative;
  overflow-x: hidden;
  height: 100%;
}

.performance-list-page > h1 {
  padding-left: 10px;
  z-index: 99;
}

.performance-list-container {
  border-radius: 0px 0px 10px 10px;
  padding: 0px 20px;
  position: relative;
  overflow-y: visible; 
  width: 70%;
  z-index: 8;
}

.performance-list-container > a {
  text-decoration: none;
}

.performance-list-container-border {
  border-right: 2px dashed var(--purple);
  border-left: 2px dashed var(--purple);
  border-bottom: 2px dashed var(--purple);
  border-radius: 0px 0px 10px 10px;
  height: calc(var(--app-height) - 20vH);
  position: absolute;
  overflow-y: visible; 
  width: 70%;
  z-index: 9;
  pointer-events: none;
  left: 60px;
}

.performance-card {
  background-position: center;
  background-size: cover;
  display: flex;
  height: 50vH;
  align-items: flex-end;
  margin-bottom: 20px;
}

.performance-card-info {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(55,55,207,0)100%);
  color: white;
  padding: 10px;
  width: 100%;
}

.performance-card-info > h1 {
  margin-bottom: 0px;
}

.performance-card-info > p {
  margin-top: 0px;
}

.texture-top-left {
  top: 50px;
  left: -200px;
  position: absolute;
  mix-blend-mode: darken;
}

.texture-bottom-right {
  bottom: 0px;
  right: -200px;
  position: absolute;
  mix-blend-mode: darken;
}

/* === Writing List === */

.writing-list-page {
  background-color: var(--sky-blue);
  display: flex;
  justify-content: space-between;
  min-height: var(--app-height);
  padding:  10vH 60px 0px 60px;
  position: relative;
}

.writing-list-container {
  border-right: 2px dashed var(--purple);
  border-left: 2px dashed var(--purple);
  padding: 0px 20px;
  position: relative;
  overflow-y: visible; 
  width: 70%;
  z-index: 9;
}

.writing-list-container > a {
  text-decoration: none;
}

.writing-card {
  background-position: center;
  background-size: cover;
  display: flex;
  height: 50vH;
  align-items: flex-end;
  margin-bottom: 20px;
}

.writing-card-info {
  background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(55,55,207,0)100%);
  color: white;
  padding: 10px;
}

.writing-card-info > h1 {
  margin-bottom: 0px;
}

.writing-card-info > div > h3 {
  margin: 0px;
}

.writing-card-info > div > p {
  margin-top: 0px;
}

.writing-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  mix-blend-mode: darken;
  opacity: 30%;
}

.writing-date {
  text-align: right;
}

/* ==== SYNDICATE === */

.syndicate-page {
  background-color: var(--purple);
  overflow: hidden;
  position: relative;
  padding: var(--app-padding);
  color: white;
}

.syndicate-intro {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.syndicate-description {
  width: 50%;
}

.syndicate-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  mix-blend-mode: darken;
  opacity: 100%;
  z-index: 0;
}

.collaborator-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 40px;
}

.collaborator {
  width: 300px;
}

.collaborator > img {
  width: 300px;
}

.syndicate-projects {
  display: flex;
  justify-content: space-between;
}

.syndicate-project-list {
  position: relative;
  width: 70%;
}

.syndicate-project-list > a {
  text-decoration: none;
}

.collaborator > p > a {
  color: white;
  text-decoration-thickness: 1px;
  transition-duration: 200ms;
}

.collaborator > p > a:hover {
  color: var(--light-pink);
  transition-duration: 200ms;
}

.collaborator > p {
  margin-top: 0;
}

.collaborator > h3 {
  margin-bottom: 0;
}

